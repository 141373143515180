import {
  Box,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";

export const colorStatus = (status) => {
  const color = {
    aprovado: "#89BD23",
    recusado: "#FF5722",
    pendente: "#F6BE15",
    cancelado: "#520000",
    em_transito: "#9C27B0",
    entregue: "#2089DC",
    reagendado: "#520000",
  };

  return color[status] || "#F6BE15";
};

export const getComplaintColorStatus = (status) => {
  const color = {
    1: "#E11414",
    2: "#0788FF",
    3: "#B007FF",
    4: "#FF0760",
    5: "#89BD23",
  };

  return color[status] || "#E11414";
};

export const iconStatusStyle = makeStyles(({ statusId }) => ({
  complaintStatusIcon: {
    width: 16,
    height: 16,
    "& path": {
      fill: (props) => getComplaintColorStatus(props.statusId),
    },
  },
}));

export const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 26,
    paddingRight: 32,
    paddingTop: 16,
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    color: "#757575",
    gap: "0.5rem",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 400,
  },
  titleDetail: {
    display: "flex",
    marginTop: 16,
    "& h1": {
      fontFamily: "Inter",
      fontSize: 22,
      fontWeight: 600,
    },

    "@media (max-width:1200px)": {
      "& h1": {
        fontFamily: "Inter",
        fontSize: 14,
        fontWeight: 600,
      },
    },
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    marginTop: 32,
    gap: 24,

    "@media (max-width:1200px)": {
      flexDirection: "column",
    },
  },
  cardAlert: {
    display: "flex",
    gap: 24,

    "@media (max-width:1200px)": {
      flexDirection: "column",
    },
  },
  notReturnedCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    padding: 8,
    width: "100%",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,

    "& .major": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },

    "& p": {
      margin: 0,
      marginLeft: 18,
      color: "#E11414",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    border: "0.5px solid #C4C4C4",
    padding: 16,
    width: "100%",
    minHeight: 440,
  },
  rescheduleCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "0.5px solid #C4C4C4",
    padding: 16,
    width: "100%",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,

    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },

    "& p": {
      margin: 0,
      marginLeft: 18,
    },

    "& a": {
      minWidth: 135,
      textAlign: "center",
    },
  },
  refuseCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "0.5px solid #C4C4C4",
    padding: 16,
    width: "100%",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,

    "& .major": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },

    "& p": {
      margin: 0,
      marginLeft: 18,
    },

    "& span": {
      margin: 0,
      marginLeft: 18,
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 500,
      color: "#6a6e73",
    },
    "& a": {
      minWidth: 135,
    },
  },
  titleCard: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Inter",
    fontSize: 18,
    fontWeight: 600,

    "& svg": {
      width: 18.75,
      height: 18.75,
      color: "#757575",
      cursor: "pointer",
    },
  },
  orderStatusCard: {
    display: "flex",
    textAlign: "end",
    height: 19,
    gap: 8,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 16,
    textTransform: "capitalize",

    "& svg": {
      width: 14,
      height: 14,
    },
  },
  orderBodyCard: {
    display: "flex",
    gap: 8,
    marginTop: 24,

    "@media (max-width:1200px)": {
      flexDirection: "column",
    },

    "& .bodyInfo": {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      "& .infoItem": {
        display: "flex",
        flexDirection: "column",
        marginBottom: 16,
        "& .title": {
          fontFamily: "Inter",
          fontSize: 14,
          fontWeight: 500,
          color: "#757575",
          textTransform: "capitalize",
        },
        "& .content": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
        },
      },
    },
  },
  orderFooterCard: {
    display: "flex",
    flexDirection: "column",

    "& .title": {
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 500,
      color: "#757575",
    },
    "& .content": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 400,
    },
    "& .contentImg": {
      display: "flex",
      width: "100%",
      height: 95,
      border: "1px solid black",
      cursor: "pointer",

      "& img": {
        maxWidth: "100%",
        objectFit: "cover",
      },
    },
  },
  orderButtonsCard: {
    display: "flex",
    justifyContent: "end",
    gap: 8,
    marginTop: 24,
    alignItems: "flex-end",
    height: "100%",

    "& span": {
      display: "flex",
      gap: 8,

      "& a": {
        textDecoration: "none",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        color: "#3EA0DE",
      },

      "& .noLink": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        color: "#757575",
      },

      "& svg": {
        width: 20,
        height: 20,
        color: "#6E8247",
      },
    },
  },

  receiptRefundButton: {
    display: "flex",
    gap: 8,
    marginTop: 24,

    "& span": {
      textDecoration: "none",
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      cursor: "pointer",
      color: "#3EA0DE",
    },

    "& svg": {
      width: 20,
      height: 20,
      color: "#6E8247",
    },
  },

  deliveryButtonsCard: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end",
    gap: 8,
    height: "100%",
    marginTop: 24,

    "& span": {
      display: "flex",
      gap: 8,

      "& a": {
        textDecoration: "none",
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        color: "#3EA0DE",
      },

      "& .noLink": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        color: "#757575",
      },

      "& svg": {
        width: 20,
        height: 20,
        color: "#6E8247",
      },
    },
  },

  historyTitle: {
    paddingBlock: 19,
    paddingInline: 8,
    borderBottom: "1px solid #E0E0E0",

    "& h4": {
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 600,
      color: "#353535",
    },
  },

  historyTable: {
    minWidth: 968,
    marginBottom: "1em",

    "& .updateTitle": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      color: "#757575",
      paddingInline: 8,

      "& span": {
        display: "block",
      },
    },

    "& tr th": {
      fontFamily: theme.font.Roboto,
      fontSize: "0.875rem",
      fontWeight: 500,
      color: theme.colors.primary,
    },

    "& tr td": {
      fontFamily: theme.font.Roboto,
      fontSize: "1rem",
      fontWeight: 400,
      color: theme.colors.primary,
    },

    "& .titleStatus": {
      paddingInline: 8,
      width: 16,
    },

    "& .titleId": {
      paddingInline: 8,
      width: 67,
    },

    "& .titleDate": {
      paddingInline: 8,
      width: 94,
    },

    "& .titleValue": {
      paddingInline: 8,
      width: 92,
    },

    "& .titleNegotiation": {
      paddingInline: 8,
      width: 150,
    },
    "& .titleDetailNegotiation": {
      paddingInline: 8,
      width: 188,
    },
    "& .titleUpdated": {
      paddingInline: 8,
      width: 98,
    },
    "& .titleAction": {
      paddingInline: 8,
      width: 181,
    },

    "& .contentStatus": {
      paddingInline: 8,
      width: 16,
    },

    "& .contentId": {
      paddingInline: 8,
      width: 67,
    },

    "& .contentDate": {
      paddingInline: 8,
      width: 94,

      "& span": {
        display: "block",

        "& .date": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
          color: "#353535",
        },

        "& .time": {
          fontFamily: "Roboto",
          fontSize: 12,
          fontWeight: 400,
          color: "#353535",
        },
      },
    },

    "& .contentValue": {
      paddingInline: 8,
      width: 92,
    },

    "& .contentNegotiation": {
      paddingInline: 8,
      width: 150,
    },

    "& .contentUpdated": {
      paddingInline: 8,
      width: 98,

      "& span": {
        display: "block",

        "& .date": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
          color: "#353535",
        },

        "& .time": {
          fontFamily: "Roboto",
          fontSize: 12,
          fontWeight: 400,
          color: "#353535",
        },
      },
    },

    "& .contentAction": {
      textAlign: "end",
      paddingInline: 8,
      width: 181,
    },
  },

  orderTableContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    border: "0.5px solid #C4C4C4",
    marginTop: 24,
    paddingInline: 24,
  },

  orderTableContent: {
    maxHeight: 620,
    overflow: "auto",
  },

  historyTableContent: {
    maxHeight: 620,
    overflow: "auto",
  },

  orderTable: {
    minWidth: 968,
    marginBottom: "1em",
  },

  orderTableHead: {
    display: "flex",
    justifyContent: "space-between",
    paddingInline: 8,
    paddingBlock: 19,
    borderBottom: "1px solid #E0E0E0",
    "& h4": {
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 600,
      color: "#353535",
    },

    "& .action": {
      display: "flex",
      textAlign: "right",
      gap: "1.5rem",
      "@media (max-width:800px)": {
        width: "100%",
        textAlign: "center",
        gap: ".5rem",
        flexDirection: "column",
      },
    },

    "@media (max-width:800px)": {
      width: "100%",
      flexDirection: "column",
    },
  },

  orderTableBody: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    color: "#353535",

    "& .newValue": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      color: "#353535",
    },

    "& .oldValue": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      color: "#757575",
      textDecoration: "line-through",
      textDecorationColor: "#E11414",
    },

    "& .sku": {
      width: 159,
      paddingInline: 8,
    },
    "& .description": {
      width: 559,
      paddingInline: 16,
    },
    "& .quantity": {
      width: 267,
      paddingInline: 16,
    },
    "& .quantityScratched": {
      width: 267,
      paddingInline: 16,
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    "& .total": {
      textAlign: "center",
      width: 95,
      paddingInline: 16,
    },
    "& .totalScratched": {
      textAlign: "center",
      width: 95,
      paddingInline: 16,
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
  },

  orderTableFooter: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    paddingBlock: 24,

    "& .subtotal": {
      display: "flex",
      gap: 8,

      "& .subtotalText": {
        color: "#757575",
        width: 100,
      },

      "& .subtotalValue": {
        color: "#353535",
        width: 80,
        textAlign: "end",
      },
    },

    "& .missing": {
      display: "flex",
      gap: 8,

      "& .missingText": {
        color: "#757575",
        width: 100,
      },

      "& .missingValue": {
        color: "#E11414",
        width: 80,
        textAlign: "end",
      },
    },

    "& .shipping": {
      display: "flex",
      gap: 8,

      "& .shippingText": {
        color: "#757575",
        width: 100,
      },

      "& .shippingValue": {
        color: "#353535",
        width: 80,
        textAlign: "end",
      },
    },

    "& .total": {
      display: "flex",
      alignItems: "end",
      gap: 8,

      "& .totalText": {
        color: "#757575",
        width: 100,
      },

      "& .totalValue": {
        fontSize: 16,
        fontWeight: 600,
        color: "#353535",
        width: 80,
        textAlign: "end",
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  schedulingContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 612,
    width: "90%",
    minHeight: 545,
    maxHeight: "80vh",
    overflow: "auto",
    padding: 16,
    backgroundColor: "#FFF",

    "@media (max-width:1200px)": {
      alignItems: "baseline",
      height: "100%",
    },

    "& .schedulingCloseButton": {
      width: "100%",
      textAlign: "end",
      color: "#757575",

      "& svg": {
        width: 20,
        height: 20,
        cursor: "pointer",
      },
    },

    "& .schedulingTitle": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      fontFamily: "Inter",
      fontSize: 20,
      fontWeight: 600,
      width: "90%",

      "& svg": {
        width: 24,
        height: 24,
        color: "#757575",
        cursor: "pointer",
      },

      "@media (max-width:1200px)": {
        fontSize: 14,
        marginBottom: "1rem",
        "& svg": {
          width: 14,
          height: 14,
        },
      },
    },

    "& .schedulingSubTitle": {
      display: "flex",
      gap: 8,
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: 600,
      color: "#757575",
      paddingLeft: 3,
      width: "87%",

      "@media (max-width:1200px)": {
        fontSize: 14,
      },
    },

    "& .reschedulingSubTitle": {
      display: "flex",
      gap: 8,
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: 600,
      color: "#757575",
      paddingLeft: 32,
      width: 320,

      "@media (max-width:1200px)": {
        fontSize: 14,
      },
    },

    "& .reschedulingValues": {
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 500,
      color: "#757575",
      paddingLeft: 32,
      paddingBottom: 3,
      width: 320,

      "@media (max-width:1200px)": {
        fontSize: 14,
      },
    },

    "& .dateTimeContent": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 auto",
      width: "100%",
      gap: "1.5rem",
      marginTop: "2rem",

      "& .msgError": {
        marginTop: 8,
        fontFamily: "Roboto",
        fontSize: 11,
        fontWeight: 400,
        color: "#E11414",
        width: 163,
      },
    },
  },

  paymentEditContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 612,
    width: "90%",
    minHeight: 545,
    maxHeight: "80vh",
    overflow: "auto",
    padding: 16,
    backgroundColor: "#FFF",

    "@media (max-width:1200px)": {
      alignItems: "baseline",
      height: "100%",
    },

    "& .schedulingCloseButton": {
      width: "100%",
      textAlign: "end",
      color: "#757575",

      "& svg": {
        width: 20,
        height: 20,
        cursor: "pointer",
      },
    },

    "& .schedulingTitle": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      fontFamily: "Inter",
      fontSize: 20,
      fontWeight: 600,
      width: "90%",

      "& svg": {
        width: 24,
        height: 24,
        color: "#757575",
        cursor: "pointer",
      },

      "@media (max-width:1200px)": {
        fontSize: 14,
        marginBottom: "1rem",
        "& svg": {
          width: 14,
          height: 14,
        },
      },
    },

    "& .schedulingSubTitle": {
      display: "flex",
      gap: 8,
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: 600,
      color: "#757575",
      paddingLeft: 3,
      width: "87%",

      "@media (max-width:1200px)": {
        fontSize: 14,
      },
    },

    "& .reschedulingSubTitle": {
      display: "flex",
      gap: 8,
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: 600,
      color: "#757575",
      paddingLeft: 32,
      width: 320,

      "@media (max-width:1200px)": {
        fontSize: 14,
      },
    },

    "& .reschedulingValues": {
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 500,
      color: "#757575",
      paddingLeft: 32,
      paddingBottom: 3,
      width: 320,

      "@media (max-width:1200px)": {
        fontSize: 14,
      },
    },

    "& .dateTimeContent": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 auto",
      width: "-webkit-fill-available",
      maxWidth: "366px",
      gap: "1.5rem",
      marginTop: "2rem",

      "& .msgError": {
        marginTop: 8,
        fontFamily: "Roboto",
        fontSize: 11,
        fontWeight: 400,
        color: "#E11414",
        width: 163,
      },
    },
  },

  editPaymentObservation: {
    width: "100%",
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,

      "& svg": {
        color: "#757575",
      },
    },
  },

  editPaymentDateInput: {
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
    },

    "& input": {
      cursor: "pointer",
    },
    "& .Mui-disabled": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "&.MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      color: "#757575",
      width: "100%",
      "& fieldset": {
        borderColor: "#757575",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },
  },

  editPaymentFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    marginTop: 20,
    justifyContent: "center",

    "& button": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 8,
      color: "#fff",
      width: "100%",
      maxWidth: "366px",
      height: 35,
      paddingInline: 8,
      paddingBlock: 16,
      backgroundColor: "#89BD23",
      textTransform: "none",
      "&:disabled": {
        cursor: "not-allowed",
        pointerEvents: "all !important",

        "&:hover": {
          backgroundColor: "#89BD23",
        },
      },

      "&:hover": {
        backgroundColor: "#89BD23",
      },
    },
  },

  detailModalContent: {
    display: "flex",
    flexDirection: "column",
    width: 544,
    overflow: "auto",
    paddingBlock: 8,
    paddingInline: 8,
    backgroundColor: "#FFF",

    "@media (max-width:800px)": {
      width: "95%",
      height: "95%",
    },

    "& .closeIcon": {
      textAlign: "end",
      "& svg": {
        color: "#757575",
        width: 20,
        height: 20,
        cursor: "pointer",
      },
    },

    "& .content": {
      display: "flex",
      flexDirection: "column",
      paddingInline: "1rem",
      gap: "1.5rem",

      "& .head": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "1rem",
        "& span": {
          fontFamily: "Inter",
          fontSize: 16,
          fontWeight: 600,
          color: "#353535",
        },

        "& .icons": {
          display: "flex",
          gap: "1.125rem",
          "& svg": {
            color: "#757575",
            width: 20,
            height: 22,
            cursor: "pointer",
          },
        },
      },

      "& .breakItems": {
        display: "flex",
        flexDirection: "column",
        padding: 8,
        overflowX: "auto",
        border: "1px solid #757575",
        borderRadius: 4,

        "& h2": {
          fontFamily: "Inter",
          fontSize: 16,
          fontWeight: 600,
          color: "#353535",
        },

        "& .itemsTitles": {
          display: "flex",
          marginTop: 8,

          "& .title, .titleEnd": {
            display: "flex",
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 500,
            color: "#353535",
            alignItems: "flex-end",
          },

          "& .titleEnd": {
            textAlign: "end",
          },
        },

        "& .items": {
          display: "flex",

          "& .item, .itemEnd": {
            display: "flex",
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: 400,
            color: "#353535",
            alignItems: "flex-end",
          },

          "& .itemEnd": {
            display: "flex",
            borderRadius: 4,
            justifyContent: "center",
            paddingBlock: 8,
            paddingInline: 16,
          },
        },
      },

      "& .comments": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        minHeight: 110,
        overflowX: "auto",
        padding: 8,
        border: "1px solid #757575",
        borderRadius: 4,

        "& h2": {
          fontFamily: "Inter",
          fontSize: 16,
          fontWeight: 600,
          color: "#353535",
        },

        "& span": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
          color: "#353535",
        },
      },

      "& .info": {
        display: "flex",
        flexDirection: "column",
        padding: 8,
        border: "1px solid #757575",
        borderRadius: 4,

        "& .infoTitle": {
          display: "flex",
          justifyContent: "space-between",
          "& h2": {
            fontFamily: "Inter",
            fontSize: "1rem",
            fontWeight: 600,
            color: theme.colors.primary,
          },
        },

        "& h2": {
          fontFamily: "Inter",
          fontSize: "1rem",
          fontWeight: 600,
          color: theme.colors.primary,
        },

        "& h3": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
          color: "#757575",
        },

        "& span": {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: 400,
          color: "#353535",
        },
      },
      "& .solutionInfo": {
        display: "flex",
        flexDirection: "column",
        padding: 8,

        "& h3": {
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 400,
          color: "#757575",
        },

        "& span": {
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 400,
          color: "#353535",
        },
      },
      "& .alertInfo": {
        display: "flex",
        flexDirection: "column",
        padding: 8,

        "& h3": {
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 400,
          color: "#E11414",
        },
      },

      "& .action": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "2rem",
        gap: "1rem",
        "& button": {
          width: "100%",
          paddingInline: 0,
        },
        "@media (max-width:800px)": {
          flexDirection: "column",
          gap: "0.5rem",

          "& button": {
            width: "100%",
          },
        },
      },
      "& .principal-action": {
        display: "flex",
        marginBottom: "0.5rem",
        width: "100%",
        "& button": {
          width: "100%",
        },
      },
    },
  },
  invoiceImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    padding: 100,

    "& .actionButton": {
      width: "100%",
      textAlign: "end",
      color: "#fff",

      "& span": {
        cursor: "pointer",
        "& a": {
          textDecoration: "none",
          color: "#fff",
        },
      },
    },
    "& .invoiceImage": {
      display: "flex",
      width: "100%",
      height: "100%",
      "& img": {
        maxWidth: "100%",
        objectFit: "contain",
      },
    },
    "@media (max-width:1200px)": {
      padding: 16,
      "& .invoiceImage": {
        "& img": {
          maxWidth: "100%",
        },
      },
    },
  },
  dateInput: {
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
    },

    "& input": {
      cursor: "pointer",
    },
    "& .Mui-disabled": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      color: "#757575",
      width: "15.0625rem",
      "& fieldset": {
        borderColor: "#757575",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },
  },
  selectTime: {
    width: "15.0625rem",
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,

      "& svg": {
        color: "#757575",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    "& .MuiPaper-root": {
      backgroundColor: "black",
    },
  },
  selectReason: {
    width: "15.0625rem",
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,

      "& svg": {
        color: "#757575",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    "& .MuiPaper-root": {
      backgroundColor: "black",
    },
  },

  rescheduleObservation: {
    width: "15.0625rem",
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,

      "& svg": {
        color: "#757575",
      },
    },
  },
  schedulingFooter: {
    display: "flex",
    marginTop: 40,
    justifyContent: "end",
    paddingInline: 121,

    "& button": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      color: "#fff",
      width: 77,
      height: 35,
      paddingInline: 8,
      paddingBlock: 16,
      backgroundColor: "#89BD23",
      textTransform: "none",
      "&:disabled": {
        cursor: "not-allowed",
        pointerEvents: "all !important",

        "&:hover": {
          backgroundColor: "#89BD23",
        },
      },

      "&:hover": {
        backgroundColor: "#89BD23",
      },
    },

    "@media (max-width:1200px)": {
      justifyContent: "center",
      paddingInline: 0,
      width: "100%",
      "& button": {
        width: 165,
      },
    },
  },

  rescheduleRetryCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "0.5px solid #C4C4C4",
    padding: 16,
    width: "90%",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    margin: "0 auto",

    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },

    "& p": {
      margin: 0,
      marginLeft: 18,
    },

    "& a": {
      minWidth: 135,
      textAlign: "center",
    },
  },

  cancelButton: {
    backgroundColor: "#FFF !important",
    color: "rgb(117, 117, 117) !important",

    "&:hover": {
      backgroundColor: "#e1e1e185!important",
    },

    "& .removeItem": {
      color: "rgb(117, 117, 117)",
      textDecoration: "line-through",
      textDecorationColor: "rgb(225, 20, 20)",
    },
  },

  removeItem: {
    color: "rgb(117, 117, 117)",
    textDecoration: "line-through",
    textDecorationColor: "rgb(225, 20, 20)",
  },

  reschedulingFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    marginTop: 40,
    paddingInline: 121,

    "& button": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 8,
      color: "#fff",
      minWidth: "500px",
      height: 35,
      paddingInline: 8,
      paddingBlock: 16,
      backgroundColor: "#89BD23",
      textTransform: "none",
      "&:disabled": {
        cursor: "not-allowed",
        pointerEvents: "all !important",

        "&:hover": {
          backgroundColor: "#89BD23",
        },
      },

      "&:hover": {
        backgroundColor: "#89BD23",
      },
    },

    "@media (max-width:1200px)": {
      justifyContent: "center",
      paddingInline: 0,
      width: "100%",
      "& button": {
        width: "90%",
      },
    },
  },

  couponModalContent: {
    display: "block",
    width: 544,
    height: "auto",
    overflow: "auto",
    padding: 16,
    backgroundColor: "#FFF",

    "@media (max-width:800px)": {
      width: "95vw",
      height: "80vh",
    },

    "& .title": {
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 600,
      color: "#353535",
      marginBottom: 24,
    },

    "& .oneField": {
      marginTop: 16,
    },
    "& .twofields": {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: 16,
      marginTop: 16,
    },

    "& .actions": {
      display: "flex",
      justifyContent: "flex-end",
      gap: 24,
      marginTop: 16,
      "& .btnCancel": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#353535",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#DBE4C7",
      },

      "& .btnSave": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#FAFAFA",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#89BD23",
      },
    },
  },
  confirmModalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 493,
    minHeight: 209,
    overflow: "auto",
    padding: 13,
    backgroundColor: "#FFF",

    "& .closeIcon": {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      color: "#757575",
      "& svg": {
        cursor: "pointer",
      },
    },

    "& .title": {
      marginTop: 16,
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
    },

    "& .actions": {
      display: "flex",
      gap: 48,
      marginTop: 48,
      "& .btnCancel": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#353535",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#DBE4C7",
      },

      "& .btnSave": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#FAFAFA",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#89BD23",
      },
    },
  },
  otherNegotiationsModalContent: {
    width: 544,
    height: 449,
    overflow: "auto",
    padding: 16,
    backgroundColor: "#FFF",

    "& .title": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      fontFamily: "Inter",
      fontSize: 18,
      fontWeight: 600,
      color: "#353535",
      marginBottom: 24,

      "& svg": {
        width: 20,
        height: 20,
        color: "#757575",
        cursor: "pointer",
      },
    },

    "& .oneField": {
      marginTop: 16,
    },
    "& .twofields": {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: 16,
      marginTop: 16,
    },

    "& .actions": {
      display: "flex",
      justifyContent: "flex-end",
      gap: 24,
      marginTop: 16,
      "& .btnCancel": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#353535",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#DBE4C7",
      },

      "& .btnSave": {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 500,
        color: "#FAFAFA",
        textTransform: "none",
        paddingInline: 16,
        backgroundColor: "#89BD23",
      },
    },
    "& .missingItems": {
      marginBlock: 32,
      "& .title": {
        alignItems: "flex-end",
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },

  dateTimeContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    "& .msgError": {
      marginTop: 8,
      fontFamily: "Roboto",
      fontSize: 11,
      fontWeight: 400,
      color: "#E11414",
      width: 163,
    },
  },
  defaultInput: {
    marginTop: 8,
    "& > *": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
    },

    "& .Mui-disabled": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      color: "#757575",
      width: 235,
      "& fieldset": {
        borderColor: "#757575",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#757575",
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 4000,
    color: "#fff",
  },
}));

export const CancelRefusedContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  background: "#FFF",
  paddingInline: "1.5rem",
  paddingBottom: "2.5rem",
  gap: "1.5rem",
});

export const CancelRefusedSubTitle = styled(Box)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.1875rem",
  color: theme.colors.primary,
}));

export const CancelRefusedSelectContent = styled(Box)({
  width: "10.75rem",

  "& .MuiOutlinedInput-root": {
    height: "3.1875rem",
  },
});

export const CancelRefusedObservationContent = styled(Box)({
  "@media (max-width:800px)": {
    width: "100%",
  },
});

export const CancelRefusedDivContent = styled(Box)({
  "@media (max-width:800px)": {
    width: "100%",
  },
});

export const CancelRefusedActionContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: "1.5rem",
  marginTop: "0.5rem",
});

export const StyledOrderTable = styled(Table)({
  minWidth: 968,
  marginBottom: "1em",
});

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  color: theme.colors.primary,
  "& tr th": {
    padding: "1rem 0.5rem",
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  paddingLeft: 16,
  "& tr td": {
    padding: ".5rem",
    minHeight: "3.8125rem",
    verticalAlign: "middle",
  },
  "& .qtyTotal": {
    textAlign: "center",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .sku": {
    width: "10.4375rem",
  },
  "& .itemName": {
    width: "22.4375rem",
  },
  "& .qty": {
    width: "5.625rem",
  },
  "& .qtyTotal": {
    width: "5.0625rem",
  },
  "& .value": {
    width: "5.625rem",
  },
}));

export const OrderTableProductContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",

  "& .portionName": {
    color: theme.colors.lightGray,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    textTransform: "capitalize",
  },
}));

export const OrderTableQtyContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",

  "& .originalQty": {
    color: theme.colors.lightGray,
    textDecoration: "line-through",
    textDecorationColor: theme.colors.alertRed,
  },
}));

export const OrderTableValueContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",

  "& .originalValue": {
    color: theme.colors.lightGray,
    textDecoration: "line-through",
    textDecorationColor: theme.colors.alertRed,
  },
}));

export const NoSolutionModalTitleContent = styled(Box)({
  paddingInline: "1rem",
});

export const NoSolutionModalContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  paddingInline: "1rem",
  marginTop: "1.5rem",
});

export const NoSolutionModalSubTitle = styled(Box)(({ theme }) => ({
  fontFamily: theme.font.Roboto,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.1875rem",
}));

export const NoSolutionModalAction = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  paddingInline: "1rem",
  gap: "1.5rem",
  marginBlock: "1.5rem",
});

export const AlertInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0.5rem",

  "& h3": {
    fontFamily: theme.font.Roboto,
    fontSize: "0.875rem",
    fontWeight: 400,
    color: theme.colors.alertRed,
  },
}));

export const PixContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

export const PixTypeAndKeyContainer = styled(Box)({
  display: "flex",
  gap: "1.5rem",
});

export const PixTypeContent = styled(Box)({
  display: "flex",
});

export const PixKeyContent = styled(Box)({
  display: "flex",
  flex: 1,
});

export const TransfBankerContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

export const BankAndAgencyContent = styled(Box)({
  display: "flex",
  gap: "1.5rem",
});

export const TypeAndAccountContent = styled(Box)({
  display: "flex",
  gap: "1.5rem",
});
