import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { IoMdDownload } from "react-icons/io";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import SelectTime from "./selectTime";
import { Button } from "../../../components/Button";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Calendar from "../../../components/Calendar/ScheduleCalendar";
import { StatusIcon } from "../../../components/Icons/StatusIcon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  CancelRefusedContent,
  NoSolutionModalAction,
  NoSolutionModalContent,
  NoSolutionModalSubTitle,
  NoSolutionModalTitleContent,
  PixContent,
  useStyle,
} from "./style";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { ItemsTable } from "../../../components/Table/ItemsTable";
import { ModalBody } from "../../../components/Modals/ModalBody";
import { Modal } from "../../../components/Modals/Modal";
import { H1 } from "../../../components/Heading/Heading";
import { DefaultInput } from "../../../components/Inputs/DefaultInput";
import {
  BackIconButton,
  CloseIconButton,
  DeleteIconButton,
  EditIconButton,
} from "../../../components/StyledIconButton";
import { useState } from "react";
import { toast } from "react-toastify";
import { InitialScreen, BasicRefuse } from "./refusedBody/refusedBody";
import {
  OrderTableProductContent,
  OrderTableQtyContent,
  OrderTableValueContent,
  StyledOrderTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
} from "./style";
import { TableCell } from "@material-ui/core";

export const DetailCoupon = ({
  complaint,
  handleClose,
  handleConfirm,
  hasPermission,
}) => {
  const classes = useStyle();
  const isPermitted =
    hasPermission("Can add action") || hasPermission("abacate | farmers");
  return (
    <Box className={classes.detailModalContent}>
      {complaint?.action?.name === "Cupom" && (
        <>
          <Box className="closeIcon">
            <CloseIconButton onClick={handleClose} />
          </Box>
          <Box className="content">
            <Box className="head">
              <span>Detalhe da Quebra</span>
            </Box>
            <ItemsTable items={complaint?.items} />
            <Box className="comments">
              <h2>
                Comentários: <span>{complaint?.observation}</span>
              </h2>
            </Box>
            <Box className="info">
              <Box className="infoTitle">
                <h2>Cupom</h2>
                {isPermitted && (
                  <DeleteIconButton
                    onClick={() => handleConfirm("deleteCoupon")}
                  />
                )}
              </Box>
              <h3>
                Chave do Cupom:{" "}
                <span>{complaint?.action_json?.coupon?.key}</span>
              </h3>
              <h3>
                Valor do Cupom:{" "}
                <span>
                  {formatCurrency(
                    complaint?.action_json?.coupon?.discountValue
                  )}
                </span>
              </h3>
              <h3>
                Válido a partir de:{" "}
                <span>
                  {formatDate(complaint?.action_json?.coupon?.startValidPeriod)}
                </span>
              </h3>
              <h3>
                Válido até:{" "}
                <span>
                  {formatDate(complaint?.action_json?.coupon?.finalValidPeriod)}
                </span>
              </h3>
            </Box>
            <Box className="solutionInfo">
              <h3>
                Criado por:{" "}
                <span>
                  {complaint?.responsible?.created_by || "Não informado"}
                </span>
              </h3>
              <h3>
                Resolvido por:{" "}
                <span>
                  {complaint?.responsible?.resolved_by || "Não informado"}
                </span>
              </h3>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export const DetailWithoutSolution = ({
  complaint,
  handleClose,
  handleConfirm,
  hasPermission,
}) => {
  const classes = useStyle();
  const isPermitted = hasPermission("Can add action");

  return (
    <Box className={classes.detailModalContent}>
      {complaint?.action?.name === "Sem tratativa" && (
        <>
          <Box className="closeIcon">
            <CloseIconButton onClick={handleClose} />
          </Box>
          <Box className="content">
            <Box className="head">
              <span>Detalhe da Quebra</span>
            </Box>
            <ItemsTable items={complaint?.items} />
            <Box className="comments">
              <h2>
                Comentários: <span>{complaint?.observation}</span>
              </h2>
            </Box>
            <Box className="info">
              <Box className="infoTitle">
                <h2>Dados da Tratativa</h2>
                {isPermitted && (
                  <DeleteIconButton
                    onClick={() => handleConfirm("deleteWithoutSolution")}
                  />
                )}
              </Box>
              <h3>
                Tratativa: <span>Sem tratativa</span>
              </h3>
              <h3>
                Valor:{" "}
                <span>
                  {formatCurrency(
                    complaint?.action_json?.without_solution?.complaintValue
                  )}
                </span>
              </h3>
            </Box>
            <Box className="solutionInfo">
              <h3>
                Criado por:{" "}
                <span>
                  {complaint?.responsible?.created_by || "Não informado"}
                </span>
              </h3>
              <h3>
                Resolvido por:{" "}
                <span>
                  {complaint?.responsible?.resolved_by || "Não informado"}
                </span>
              </h3>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export const DetailCreditedonWallet = ({
  complaint,
  orderStatus,
  handleClose,
  handleConfirm,
  hasPermission,
}) => {
  const classes = useStyle();
  const isPermitted = hasPermission("Can add action");
  const walletAmount =
    complaint?.action_json?.wallet?.amount ||
    complaint?.action_json?.wallet?.creditValue;
  const walletReason =
    complaint?.action_json?.wallet?.reason ||
    complaint?.action_json?.wallet?.observation;
  return (
    <Box className={classes.detailModalContent}>
      {complaint?.action?.name === "Creditado na carteira" && (
        <>
          <Box className="closeIcon">
            <CloseIconButton onClick={handleClose} />
          </Box>
          <Box className="content">
            <Box className="head">
              <span>Detalhe da Quebra</span>
            </Box>
            <ItemsTable items={complaint?.items} />
            <Box className="comments">
              <h2>
                Comentários: <span>{walletReason}</span>
              </h2>
            </Box>
            <Box className="info">
              <Box className="infoTitle">
                <h2>Dados da Tratativa</h2>
                {isPermitted && orderStatus !== "recusado" && (
                  <DeleteIconButton
                    onClick={() => handleConfirm("deleteWalletCredit")}
                  />
                )}
              </Box>
              <h3>
                Tratativa: <span>Creditado na Carteira</span>
              </h3>
              <h3>
                Valor: <span>{formatCurrency(walletAmount)}</span>
              </h3>
            </Box>
            <Box className="solutionInfo">
              <h3>
                Criado por:{" "}
                <span>
                  {complaint?.responsible?.created_by || "Não informado"}
                </span>
              </h3>
              <h3>
                Resolvido por:{" "}
                <span>
                  {complaint?.responsible?.resolved_by || "Não informado"}
                </span>
              </h3>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export const DetailRefund = ({
  complaint,
  handleClose,
  handleConfirm,
  hasPermission,
}) => {
  const classes = useStyle();
  const isPermitted = hasPermission("Can add action");

  return (
    <Box className={classes.detailModalContent}>
      {complaint?.action?.name === "Reembolso" && (
        <>
          <Box className="closeIcon">
            <CloseIconButton onClick={handleClose} />
          </Box>
          <Box className="content">
            <Box className="head">
              <span>Detalhe da Quebra</span>
            </Box>
            <ItemsTable items={complaint?.items} />
            <Box className="comments">
              <h2>
                Comentários: <span>{complaint?.observation}</span>
              </h2>
            </Box>
            <Box className="info">
              <Box className="infoTitle">
                <h2>Dados de Reembolso</h2>
                {isPermitted && (
                  <DeleteIconButton
                    onClick={() => handleConfirm("deleteRefund")}
                  />
                )}
              </Box>
              {complaint?.action_json?.refund?.refundOption ===
                "transferBank" && (
                <>
                  <h3>
                    Banco:{" "}
                    <span>{complaint?.action_json?.refund?.bankName}</span>
                  </h3>
                  <h3>
                    Agência:{" "}
                    <span>{complaint?.action_json?.refund?.bankBranch}</span>
                  </h3>
                  <h3>
                    Conta:{" "}
                    <span>{complaint?.action_json?.refund?.bankAccount}</span>
                  </h3>
                  <h3>
                    Tipo de Conta:{" "}
                    <span>{complaint?.action_json?.refund?.typeAccount}</span>
                  </h3>
                  <h3>
                    Valor de Devolução:{" "}
                    <span>
                      {formatCurrency(
                        complaint?.action_json?.refund?.refundValue || 0
                      )}
                    </span>
                  </h3>
                </>
              )}

              {complaint?.action_json?.refund?.refundOption === "pix" && (
                <>
                  <h3>
                    Tipo Chave Pix:{" "}
                    <span>{complaint?.action_json?.refund?.typePix}</span>
                  </h3>
                  <h3>
                    Chave Pix:{" "}
                    <span>{complaint?.action_json?.refund?.keyPix}</span>
                  </h3>
                  <h3>
                    Favorecido:{" "}
                    <span>
                      {complaint?.action_json?.refund?.favored ||
                        "Não informado"}
                    </span>
                  </h3>
                  <h3>
                    Valor de Devolução:{" "}
                    <span>
                      {formatCurrency(
                        complaint?.action_json?.refund?.refundValue || 0
                      )}
                    </span>
                  </h3>
                </>
              )}
            </Box>

            <Box className="solutionInfo">
              <h3>
                Criado por:{" "}
                <span>
                  {complaint?.responsible?.created_by || "Não informado"}
                </span>
              </h3>
              <h3>
                Resolvido por:{" "}
                <span>
                  {complaint?.responsible?.resolved_by || "Não informado"}
                </span>
              </h3>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export const DetailReposition = ({
  complaint,
  handleClose,
  handleConfirm,
  hasPermission,
}) => {
  const classes = useStyle();
  const isPermitted = hasPermission("Can add action");

  return (
    <Box className={classes.detailModalContent}>
      {complaint?.action?.name === "Reposição" && (
        <>
          <Box className="closeIcon">
            <CloseIconButton onClick={handleClose} />
          </Box>
          <Box className="content">
            <Box className="head">
              <span>Detalhe da Quebra</span>
            </Box>
            <ItemsTable items={complaint?.items} />
            <Box className="comments">
              <h2>
                Comentários: <span>{complaint?.observation}</span>
              </h2>
            </Box>
            <Box className="info">
              <Box className="infoTitle">
                <h2>Dados da Reposição</h2>
                {isPermitted && (
                  <DeleteIconButton
                    onClick={() => handleConfirm("deleteReposition")}
                  />
                )}
              </Box>
              {complaint?.action_json?.reposition?.redeliveryOption ===
                "ifood" && (
                <>
                  <h3>
                    iFood:{" "}
                    <span>
                      {formatDate(complaint?.action_json?.reposition?.date)}
                    </span>
                  </h3>
                  <h3>
                    Valor:{" "}
                    <span>
                      {formatCurrency(
                        complaint?.action_json?.reposition?.iFoodValue || 0
                      )}
                    </span>
                  </h3>
                  <h3>
                    Código da entrega:{" "}
                    <span>
                      {complaint?.action_json?.reposition?.iFoodTrackingCode}
                    </span>
                  </h3>
                </>
              )}

              {complaint?.action_json?.reposition?.redeliveryOption ===
                "nextDelivery" && (
                <>
                  <h3>
                    <span>Próximo pedido</span>
                  </h3>
                  {complaint?.action_json?.reposition?.newReplacementOrder && (
                    <h3>
                      Número do pedido:{" "}
                      <span>
                        #
                        {
                          complaint?.action_json?.reposition
                            ?.newReplacementOrder
                        }
                      </span>
                    </h3>
                  )}
                </>
              )}

              {complaint?.action_json?.reposition?.redeliveryOption ===
                "nextDay" && (
                <>
                  {complaint?.action_json?.reposition?.newReplacementOrder && (
                    <h3>
                      Número do pedido:{" "}
                      <span>
                        #
                        {
                          complaint?.action_json?.reposition
                            ?.newReplacementOrder
                        }
                      </span>
                    </h3>
                  )}
                  <h3>
                    Data:{" "}
                    <span>
                      {formatDate(
                        complaint?.action_json?.reposition?.scheduleDate
                      )}
                    </span>
                  </h3>
                  <h3>
                    Hora Início:{" "}
                    <span>
                      {`${complaint?.action_json?.reposition?.scheduleTimeFrom} hs`}
                    </span>
                  </h3>
                  <h3>
                    Hora Fim:{" "}
                    <span>
                      {`${complaint?.action_json?.reposition?.scheduleTimeTo} hs`}
                    </span>
                  </h3>
                </>
              )}
            </Box>

            <Box className="solutionInfo">
              <h3>
                Criado por:{" "}
                <span>
                  {complaint?.responsible?.created_by || "Não informado"}
                </span>
              </h3>
              <h3>
                Resolvido por:{" "}
                <span>
                  {complaint?.responsible?.resolved_by || "Não informado"}
                </span>
              </h3>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export const DiscountOnTicket = ({
  complaint,
  handleClose,
  handleConfirm,
  hasPermission,
}) => {
  const classes = useStyle();
  const isPermitted = hasPermission("Can add action");

  return (
    <Box className={classes.detailModalContent}>
      {complaint?.action?.name === "Desconto no boleto" && (
        <>
          <Box className="closeIcon">
            <CloseIconButton onClick={handleClose} />
          </Box>
          <Box className="content">
            <Box className="head">
              <span>Detalhe da Quebra</span>
              {isPermitted && (
                <DeleteIconButton
                  onClick={() => handleConfirm("deleteTicketDiscount")}
                />
              )}
            </Box>
            <ItemsTable items={complaint?.items} />
            <Box className="comments">
              <h2>
                Comentários: <span>{complaint?.observation}</span>
              </h2>
            </Box>
            <Box className="solutionInfo">
              <h3>
                Criado por:{" "}
                <span>
                  {complaint?.responsible?.created_by || "Não informado"}
                </span>
              </h3>
              <h3>
                Resolvido por:{" "}
                <span>
                  {complaint?.responsible?.resolved_by || "Não informado"}
                </span>
              </h3>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export const InvoiceImage = ({ orderData, handleClose, orderID }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.invoiceImage}>
      <span className="actionButton">
        <span onClick={handleClose}>
          <CloseIcon /> Fechar
        </span>
      </span>
      <span className="invoiceImage">
        {orderData?.delivered_data?.photo && (
          <img src={orderData?.delivered_data?.photo} alt="Comprovante" />
        )}
      </span>
      <span className="actionButton">
        <span>
          <IoMdDownload />
          <a
            href={orderData?.delivered_data?.photo}
            download={`Comprovante pedido ${orderID}`}
            rel="noreferrer"
          >
            Baixar
          </a>
        </span>
      </span>
    </Grid>
  );
};

export const Reschedule = ({
  handleClose,
  orderID,
  allowedDates,
  selectedDate,
  handleShowCalendar,
  showCalendar = false,
  handleDateChange,
  selectedTimeFrom,
  handleChangeTimeFrom,
  selectedTimeTo,
  handleChangeTimeTo,
  handleScheduledSave,
  selectRescheduleReason,
  handleChangeRescheduleReason,
  rescheduleObservation,
  handleRescheduleObservation,
  rescheduleReasonList,
}) => {
  const classes = useStyle();
  const itemsTimeFrom = [
    { value: 7, label: "7:00" },
    { value: 8, label: "8:00" },
    { value: 9, label: "9:00" },
    { value: 10, label: "10:00" },
    { value: 11, label: "11:00" },
    { value: 12, label: "12:00" },
  ];

  const itemsTimeTo = [
    {
      value: 10,
      label: "10:00",
      disabled: selectedTimeFrom + 3 > 10,
    },
    {
      value: 11,
      label: "11:00",
      disabled: selectedTimeFrom + 3 > 11,
    },
    {
      value: 12,
      label: "12:00",
      disabled: selectedTimeFrom + 3 > 12,
    },
    {
      value: 13,
      label: "13:00",
      disabled: selectedTimeFrom + 3 > 13,
    },
    {
      value: 14,
      label: "14:00",
      disabled: selectedTimeFrom + 3 > 14,
    },
    {
      value: 15,
      label: "15:00",
      disabled: selectedTimeFrom + 3 > 15,
    },
  ];

  const isDateEnabled = (date) => {
    return allowedDates.some((enabledDate) => {
      return (
        date.getDate() === enabledDate.getDate() &&
        date.getMonth() === enabledDate.getMonth() &&
        date.getFullYear() === enabledDate.getFullYear()
      );
    });
  };

  return (
    <Grid className={classes.schedulingContent}>
      <span className="schedulingCloseButton">
        <CloseIconButton onClick={handleClose} />
      </span>
      <span className="schedulingTitle">
        <BackIconButton onClick={handleClose} />
        Reagendar
      </span>
      <span className="schedulingSubTitle">{`Pedido # ${orderID}`}</span>

      <Box className="dateTimeContent">
        <TextField
          variant="outlined"
          label="Data"
          value={formatDate(selectedDate)}
          format="dd/MM/yyyy"
          disabled={true}
          classes={{ root: classes.dateInput }}
          onClick={handleShowCalendar}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />
        {showCalendar && (
          <Grid>
            <Calendar
              value={selectedDate}
              onChange={handleDateChange}
            />
          </Grid>
        )}

        <FormControl variant="outlined" className={classes.selectTime}>
          <SelectTime
            label="Hora Início"
            icon={<AccessTimeIcon />}
            value={selectedTimeFrom}
            onChange={handleChangeTimeFrom}
            menuItems={itemsTimeFrom}
          />
        </FormControl>

        <FormControl variant="outlined" className={classes.selectTime}>
          <SelectTime
            label="Hora Fim"
            icon={<WatchLaterRoundedIcon />}
            value={selectedTimeTo}
            onChange={handleChangeTimeTo}
            menuItems={itemsTimeTo}
          />
        </FormControl>
        {selectedTimeFrom + 3 > selectedTimeTo && (
          <span className="msgError">
            Encontramos um problema. Deve haver um intervalo de 3 horas entre a
            entrega inicial e a entrega final.
          </span>
        )}
        <FormControl variant="outlined" className={classes.selectReason}>
          <InputLabel id={`labelRescheduleReason`}>Motivo</InputLabel>
          <Select
            labelId={`labelTime`}
            value={selectRescheduleReason}
            onChange={handleChangeRescheduleReason}
            label="Motivo"
            startAdornment={
              <InputAdornment position="start">
                <HelpOutlineIcon />
              </InputAdornment>
            }
          >
            <MenuItem value="0" selected disabled>
              Motivo
            </MenuItem>
            {rescheduleReasonList?.map((reason) => (
              <MenuItem key={reason?.id} value={reason?.id}>
                {reason?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          className={classes.rescheduleObservation}
        >
          <TextField
            variant="outlined"
            label="Comentários"
            multiline
            rows={4}
            value={rescheduleObservation}
            onChange={handleRescheduleObservation}
          />
        </FormControl>
      </Box>

      <Grid className={classes.rescheduleObservationContent}></Grid>

      <Grid className={classes.schedulingFooter}>
        {selectedTimeFrom + 3 > selectedTimeTo ? (
          <Button style={{ minWidth: 241 }} disabled>
            Reagendar
          </Button>
        ) : (
          <Button style={{ minWidth: 241 }} onClick={handleScheduledSave}>
            Reagendar
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export const RescheduleRetry = ({
  handleClose,
  orderID,
  handleScheduledSave,
  errorInfo,
  orderItems,
  orderTotalValue,
  setRescheduleErrorInfo,
}) => {
  const classes = useStyle();
  const [newValue, setNewValue] = useState(orderTotalValue);

  const init = () => {
    setRescheduleErrorInfo((prevRescheduleErrorInfo) => {
      const updatedProducts = prevRescheduleErrorInfo.products.map(
        (product) => {
          const itemToRemove = orderItems.find(
            (item) => item?.productId === product.id
          );
          return { ...product, value: itemToRemove.totalItemValue };
        }
      );
      const removedTotalValue = updatedProducts.reduce(
        (total, { value }) => total + value,
        0
      );
      setNewValue(orderTotalValue - removedTotalValue);
      return { ...prevRescheduleErrorInfo, products: updatedProducts };
    });
  };

  useEffect(() => {
    init();
  }, []);

  const close = () => {
    handleClose();
    setRescheduleErrorInfo(null);
  };

  return (
    <Grid className={classes.schedulingContent}>
      <span className="schedulingCloseButton">
        <CloseIconButton onClick={close} />
      </span>
      <span className="schedulingTitle">
        <BackIconButton onClick={close} />
        Editar Reagendamento
      </span>
      <span className="schedulingSubTitle">{`Pedido # ${orderID}`}</span>

      <Grid className={classes.cardContainer}>
        <Grid className={classes.rescheduleRetryCard}>
          <div>
            <ErrorOutlineIcon
              style={{ width: "1.4em", height: "auto", color: "#E11414" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <p>Há produtos fora de estoque</p>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid
        className={classes.cardContainer}
        style={{ flexDirection: "column" }}
      >
        <span className="reschedulingSubTitle">Novo Pedido</span>
        <Grid className={classes.rescheduleRetryCard}>
          <StyledOrderTable style={{ minWidth: "0px" }}>
            <StyledTableHead>
              <StyledTableRow>
                <TableCell className="itemName">Item</TableCell>
                <TableCell className="qtyTotal">Quantidade</TableCell>
                <TableCell className="value">Valor</TableCell>
              </StyledTableRow>
            </StyledTableHead>

            <StyledTableBody>
              {orderItems?.map((item) => {
                const removedItem = errorInfo.products.some(
                  (product) => item?.productId === product.id
                )
                  ? classes.removeItem
                  : "";
                return (
                  <StyledTableRow key={item?.id}>
                    <TableCell className="itemName">
                      <OrderTableProductContent>
                        <Box className={removedItem}>{item.productName}</Box>
                      </OrderTableProductContent>
                    </TableCell>
                    <TableCell className="qtyTotal">
                      {item.availableQuantity < item.totalQuantity ? (
                        <OrderTableQtyContent>
                          <Box
                            className={`originalQty ${removedItem}`}
                          >{`${item.totalQuantity} ${item.portionType}`}</Box>
                          <Box
                            className={removedItem}
                          >{`${item.availableQuantity.toLocaleString(
                            "pt-BR"
                          )} ${item.portionType}`}</Box>
                        </OrderTableQtyContent>
                      ) : (
                        <Box
                          className={removedItem}
                        >{`${item.receivedQuantity.toLocaleString("pt-BR")} ${
                          item.portionType
                        }`}</Box>
                      )}
                    </TableCell>
                    <TableCell className="value">
                      {item.newTotalItensValue ? (
                        <OrderTableValueContent>
                          <Box className={`originalValue ${removedItem}`}>
                            {item.totalItensValue}
                          </Box>
                          <Box className={removedItem}>
                            {item.newTotalItensValue}
                          </Box>
                        </OrderTableValueContent>
                      ) : (
                        <Box className={removedItem}>
                          {item.totalItensValue}
                        </Box>
                      )}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </StyledOrderTable>
        </Grid>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="reschedulingValues">{`Valor original: ${orderTotalValue.toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          )}`}</span>
          <span className="reschedulingValues">{`Valor removido: ${(
            orderTotalValue - newValue
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}`}</span>
          <span
            className="reschedulingValues"
            style={{ color: "black" }}
          >{`Novo valor: ${newValue.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}`}</span>
        </div>
      </Grid>

      <Grid className={classes.reschedulingFooter}>
        <div
          style={{ marginBottom: 10, display: "flex", alignItems: "center" }}
        >
          <ErrorOutlineIcon
            style={{ width: "1em", height: "auto", color: "#58ACFA" }}
          />
          <span
            style={{
              margin: 0,
              marginLeft: 8,
              fontSize: "14px",
              color: "#58ACFA",
            }}
          >
            O valor removido será creditado na carteira
          </span>
        </div>
        <Button
          style={{ backgroundColor: "#E11414" }}
          onClick={handleScheduledSave}
        >
          Reagendar sem o produto
        </Button>
        <Button className={classes.cancelButton} onClick={close}>
          Cancelar
        </Button>
      </Grid>
    </Grid>
  );
};

export const PaymentEdit = ({
  handleClose,
  orderID,
  selectedDate,
  handleShowCalendar,
  showCalendar = false,
  handleDateChange,
  handlePaymentEditSave,
  editObservation,
  handleEditObservation,
  handleConfirmPayment,
}) => {
  const classes = useStyle();

  const [alignment, setAlignment] = useState("right");

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null && newAlignment !== "left") {
      setAlignment(newAlignment);
    }
  };

  return (
    <Grid className={classes.paymentEditContent}>
      <span className="schedulingCloseButton">
        <CloseIconButton onClick={handleClose} />
      </span>
      <span className="schedulingTitle">
        <BackIconButton onClick={handleClose} />
        Editar pagamento
      </span>
      <span className="schedulingSubTitle">{`Pedido # ${orderID}`}</span>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        style={{ margin: "0 auto", marginTop: "20px" }}
      >
        <ToggleButton
          value="left"
          aria-label="left aligned"
          onClick={handleConfirmPayment}
        >
          Confirmar Pagamento
        </ToggleButton>
        <ToggleButton value="right" aria-label="right aligned">
          Alterar vencimento
        </ToggleButton>
      </ToggleButtonGroup>

      <Box className="dateTimeContent">
        <TextField
          variant="outlined"
          label="Data de vencimento"
          value={formatDate(selectedDate)}
          format="dd/MM/yyyy"
          disabled={true}
          classes={{ root: classes.editPaymentDateInput }}
          onClick={handleShowCalendar}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />
        {showCalendar && (
          <Grid>
            <Calendar value={selectedDate} onChange={handleDateChange} />
          </Grid>
        )}

        <FormControl
          variant="outlined"
          className={classes.editPaymentObservation}
        >
          <TextField
            variant="outlined"
            label="Comentários"
            multiline
            rows={4}
            value={editObservation}
            onChange={handleEditObservation}
          />
        </FormControl>
      </Box>

      <Grid className={classes.editPaymentFooter}>
        <Button style={{ minWidth: 241 }} onClick={handlePaymentEditSave}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export const BreakResolve = ({
  complaint,
  storeName,
  handleClose,
  handleOpenCoupon,
  handleOpenCreditAnnotation,
  handleOpenOtherNegotiations,
  handleOpenWithoutSolution,
  handleConfirm,
  hasPermission,
}) => {
  const classes = useStyle();
  const history = useHistory();
  const isPermitted = hasPermission("Can add action");
  const isPermittedCoupon =
    hasPermission("Can add action") || hasPermission("abacate | farmers");

  const complaintValue = useMemo(() => {
    return complaint.items?.reduce((acc, { complaint_value }) => {
      return acc + complaint_value;
    }, 0);
  }, [complaint]);

  const notReturned = complaint.items.some((item) => {
    const notItemReturned =
      !item?.quantity_returned || item?.quantity_returned === 0;
    const reasonAllowed =
      item?.reason?.name === "Não entregue" ||
      item?.reason?.name === "Quebra Prevista";

    return notItemReturned && !reasonAllowed;
  });

  return (
    <Box className={classes.detailModalContent}>
      <>
        <Box className="closeIcon">
          <CloseIconButton onClick={handleClose} />
        </Box>
        <Box className="content">
          <Box className="head">
            <span>Detalhe da Quebra</span>
            {isPermitted && (
              <Box className="icons">
                <EditIconButton
                  onClick={() =>
                    history.push(
                      `../${complaint?.order_id}/break/${complaint?.complaint_id}/edit`
                    )
                  }
                />
                <DeleteIconButton
                  onClick={() => handleConfirm("deleteBreak")}
                />
              </Box>
            )}
          </Box>
          {notReturned && (
            <Grid className={classes.cardAlert}>
              <Grid className={classes.notReturnedCard}>
                <div className="major">
                  <StatusIcon statusId={"10"} />
                  <div>
                    <p>Existem itens não devolvidos nessa quebra</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <ItemsTable items={complaint?.items} />

          <Box className="comments">
            <h2>
              Comentários: <span>{complaint?.observation}</span>
            </h2>
          </Box>
          <Box className="solutionInfo">
            <h3>
              Criado por:{" "}
              <span>
                {complaint?.responsible?.created_by || "Não informado"}
              </span>
            </h3>
          </Box>
          <Box className="alertInfo">
            <h3>
              <ErrorOutlineIcon /> Evitar tratativas de reposição e reembolso.
            </h3>
          </Box>
          <Box>
            {!storeName.includes("acerola") && isPermittedCoupon && (
              <Box className="principal-action">
                <Button
                  onClick={() => handleOpenCreditAnnotation(complaintValue)}
                >
                  Carteira
                </Button>
              </Box>
            )}
            <Box className="action">
              <Button
                onClick={() => handleOpenWithoutSolution(complaintValue)}
                style={{
                  visibility: isPermitted ? "visible" : "hidden",
                }}
              >
                Sem tratativa
              </Button>
              <Button
                onClick={() => handleOpenCoupon(complaintValue)}
                style={{
                  visibility: isPermittedCoupon ? "visible" : "hidden",
                }}
              >
                Cupom
              </Button>
              <Button
                onClick={() => handleOpenOtherNegotiations(complaintValue)}
                style={{
                  visibility: isPermitted ? "visible" : "hidden",
                }}
              >
                Outras tratativas
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export const CouponModalBody = ({
  orderData,
  couponCode,
  complaintValue,
  handleClose,
  handleConfirm,
}) => {
  const todayDate = new Date();
  const classes = useStyle();
  return (
    <Box className={classes.couponModalContent}>
      <Grid className="title">Novo Cupom</Grid>
      <Grid className="oneField">
        <TextField
          label="Nome do cliente"
          variant="outlined"
          fullWidth
          value={orderData.client.tradeName || orderData.client.name}
          InputProps={{
            readOnly: true,
          }}
          focused={false}
        />
      </Grid>
      <Grid className="oneField">
        <TextField
          label="Chave do Cupom"
          variant="outlined"
          value={couponCode}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          focused={false}
        />
      </Grid>
      <Grid className="twofields">
        <Grid>
          <TextField
            label="Valor do Cupom"
            variant="outlined"
            value={complaintValue}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            focused={false}
          />
        </Grid>
        <Grid>
          <TextField
            label="Forma de Uso"
            variant="outlined"
            value="Único"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            focused={false}
          />
        </Grid>
      </Grid>
      <Grid className="twofields">
        <Grid>
          <TextField
            label="Válido a partir"
            value={formatDate(new Date())}
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            focused={false}
          />
        </Grid>
        <Grid>
          <TextField
            label="Válido até"
            variant="outlined"
            value={formatDate(
              new Date(todayDate.setDate(todayDate.getDate() + 30))
            )}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            focused={false}
          />
        </Grid>
      </Grid>
      <Grid className="actions">
        <Button btn="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={() => handleConfirm("negotiation")}>
          <span>Salvar</span>
        </Button>
      </Grid>
    </Box>
  );
};

export const ConfirmModalBody = ({
  handleClose,
  handleSubmit,
  confirmText,
}) => {
  const classes = useStyle();
  return (
    <Box className={classes.confirmModalContent}>
      <span className="closeIcon">
        <CloseIconButton onClick={handleClose} />
      </span>
      <span className="title">{confirmText}</span>
      <span className="actions">
        <Button btn="secondary" onClick={handleClose}>
          Não
        </Button>
        <Button onClick={handleSubmit}>Sim</Button>
      </span>
    </Box>
  );
};

export const OtherNegotiationsModalBody = ({
  handleClose,
  orderData,
  payment,
  breakInfo,
  handleBreakInfoChange,
  orderID,
  complaintValue,
  handleConfirm,
  selectedDate,
  handleShowCalendar,
  showCalendar,
  handleDateChange,
  selectedTimeFrom,
  handleChangeTimeFrom,
  selectedTimeTo,
  handleChangeTimeTo,
  iFoodOptions,
  handleIfoodOptionsChange,
  loading,
}) => {
  const classes = useStyle();
  const startingHours = [
    { value: 7, label: "7:00" },
    { value: 8, label: "8:00" },
    { value: 9, label: "9:00" },
    { value: 10, label: "10:00" },
    { value: 11, label: "11:00" },
    { value: 12, label: "12:00" },
  ];

  const endingHours = [
    {
      value: 10,
      label: "10:00",
      disabled: selectedTimeFrom + 3 > 10,
    },
    {
      value: 11,
      label: "11:00",
      disabled: selectedTimeFrom + 3 > 11,
    },
    {
      value: 12,
      label: "12:00",
      disabled: selectedTimeFrom + 3 > 12,
    },
    {
      value: 13,
      label: "13:00",
      disabled: selectedTimeFrom + 3 > 13,
    },
    {
      value: 14,
      label: "14:00",
      disabled: selectedTimeFrom + 3 > 14,
    },
    {
      value: 15,
      label: "15:00",
      disabled: selectedTimeFrom + 3 > 15,
    },
  ];
  return (
    <Box className={classes.otherNegotiationsModalContent}>
      <Grid className="title">
        Outras Tratativas
        <CloseIconButton onClick={handleClose} />
      </Grid>

      <Grid className="oneField">
        <TextField
          label="Nome do cliente"
          variant="outlined"
          value={orderData.client.tradeName || orderData.client.name}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          focused={false}
        />
      </Grid>
      <Grid className="twofields">
        {!["RECEIVED", "CONFIRMED"].includes(payment?.status) ? (
          <Select
            native
            variant="outlined"
            defaultValue={breakInfo.negotiation}
            onChange={(e) =>
              handleBreakInfoChange("negotiation", e.target.value)
            }
          >
            <option value="" selected disabled>
              Tratativa
            </option>
            <option disabled={!orderData?.urls?.bill_url} value={5}>
              Desconto no boleto
            </option>
            <option value={3}>Reposição</option>
          </Select>
        ) : (
          <Select
            native
            variant="outlined"
            defaultValue={breakInfo.negotiation}
            fullWidth
            onChange={(e) =>
              handleBreakInfoChange("negotiation", e.target.value)
            }
          >
            <option value="" selected disabled>
              Tratativa
            </option>
            <option value={2}>Reembolso</option>
            <option value={3}>Reposição</option>
          </Select>
        )}
      </Grid>
      {breakInfo.negotiation === "5" && (
        <>
          <Grid className="oneField">
            <TextField
              variant="outlined"
              value={orderID}
              label="Nº do Pedido"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
          <Grid className="oneField">
            <TextField
              variant="outlined"
              value={formatCurrency(complaintValue)}
              label="Valor do Desconto"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              focused={false}
            />
          </Grid>
        </>
      )}

      {breakInfo.negotiation === "3" && (
        <Grid className="oneField">
          <RadioGroup
            value={breakInfo.redeliveryOption}
            fullWidth
            onChange={(e) =>
              handleBreakInfoChange("redeliveryOption", e.target.value)
            }
          >
            <FormControlLabel
              value="nextDay"
              control={<Radio color="#757575" />}
              label={`${formatDate(selectedDate)} (Dia seguinte)`}
            />
            {breakInfo?.redeliveryOption === "nextDay" && (
              <Box className={classes.dateTimeContent}>
                <TextField
                  variant="outlined"
                  label="Data"
                  value={formatDate(selectedDate)}
                  format="dd/MM/yyyy"
                  disabled={true}
                  classes={{ root: classes.dateInput }}
                  onClick={handleShowCalendar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {showCalendar && (
                  <Box style={{ width: 50 }}>
                    <Calendar
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </Box>
                )}

                <FormControl variant="outlined" className={classes.selectTime}>
                  <SelectTime
                    label="Hora Início"
                    icon={<AccessTimeIcon />}
                    value={selectedTimeFrom}
                    onChange={handleChangeTimeFrom}
                    menuItems={startingHours}
                  />
                </FormControl>

                <FormControl variant="outlined" className={classes.selectTime}>
                  <SelectTime
                    label="Hora Fim"
                    icon={<WatchLaterRoundedIcon />}
                    value={selectedTimeTo}
                    onChange={handleChangeTimeTo}
                    menuItems={endingHours}
                  />
                </FormControl>
                {selectedTimeFrom + 3 > selectedTimeTo && (
                  <span className="msgError">
                    Encontramos um problema. Deve haver um intervalo de 3 horas
                    entre a entrega inicial e a entrega final.
                  </span>
                )}
              </Box>
            )}

            <FormControlLabel
              value="ifood"
              control={<Radio color="#757575" />}
              label="iFood"
            />
            {breakInfo?.redeliveryOption === "ifood" && (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <NumberFormat
                  name="value"
                  customInput={TextField}
                  variant="outlined"
                  label="Valor do iFood"
                  decimalSeparator="."
                  onChange={(e) => handleIfoodOptionsChange(e)}
                  autoComplete="off"
                  isNumericString
                  classes={{ root: classes.defaultInput }}
                />
                <TextField
                  variant="outlined"
                  name="trackingCode"
                  value={iFoodOptions?.trackingCode}
                  label="Código da Entrega"
                  onChange={(e) => handleIfoodOptionsChange(e)}
                  classes={{ root: classes.defaultInput }}
                />
              </Box>
            )}
          </RadioGroup>
        </Grid>
      )}

      {breakInfo.negotiation === "2" && (
        <>
          <Grid className="oneField">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={breakInfo.refundOption}
              onChange={(e) =>
                handleBreakInfoChange("refundOption", e.target.value)
              }
            >
              <FormControlLabel
                value="pix"
                control={<Radio color="#757575" />}
                label="Pix"
              />
              <FormControlLabel
                value="transferBank"
                control={<Radio color="#757575" />}
                label="Transferência bancária"
              />
            </RadioGroup>
          </Grid>
          {breakInfo.refundOption === "transferBank" && (
            <Grid className="oneField">
              <Grid className="twofields">
                <Grid>
                  <TextField
                    variant="outlined"
                    label="Banco"
                    value={breakInfo.bankName}
                    onChange={(e) =>
                      handleBreakInfoChange("bankName", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid>
                  <TextField
                    variant="outlined"
                    label="Agência"
                    value={breakInfo.bankBranch}
                    onChange={(e) =>
                      handleBreakInfoChange("bankBranch", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid className="twofields">
                <Grid>
                  <Select
                    native
                    variant="outlined"
                    defaultValue={breakInfo.typeAccount}
                    fullWidth
                    onChange={(e) =>
                      handleBreakInfoChange("typeAccount", e.target.value)
                    }
                  >
                    <option value="" selected disabled>
                      Tipo de Conta
                    </option>
                    <option value="Corrente">Conta corrente</option>
                    <option value="Poupança">Conta poupança</option>
                  </Select>
                </Grid>
                <Grid>
                  <TextField
                    variant="outlined"
                    label="Conta"
                    value={breakInfo.bankAccount}
                    onChange={(e) =>
                      handleBreakInfoChange("bankAccount", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid className="oneField">
                <TextField
                  variant="outlined"
                  label="CPF/CNPJ do titular"
                  value={breakInfo.bankDocument}
                  onChange={(e) =>
                    handleBreakInfoChange("bankDocument", e.target.value)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {breakInfo.refundOption === "pix" && (
            <PixContent>
              <Grid className="twofields">
                <Grid>
                  <Select
                    native
                    variant="outlined"
                    defaultValue={breakInfo.typePix}
                    fullWidth
                    onChange={(e) =>
                      handleBreakInfoChange("typePix", e.target.value)
                    }
                  >
                    <option value="" selected disabled>
                      Chave Pix
                    </option>
                    <option value="CPF">CPF</option>
                    <option value="CNPJ">CNPJ</option>
                    <option value="E-mail">E-mail</option>
                    <option value="Celular">Celular</option>
                  </Select>
                </Grid>
                <Grid>
                  <TextField
                    variant="outlined"
                    label="Inserir chave pix"
                    value={breakInfo.keyPix}
                    onChange={(e) =>
                      handleBreakInfoChange("keyPix", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  label="Favorecido"
                  value={breakInfo.favored}
                  onChange={(e) =>
                    handleBreakInfoChange("favored", e.target.value)
                  }
                  fullWidth
                />
              </Grid>
            </PixContent>
          )}
        </>
      )}
      <Grid className="actions">
        <Button btn="secondary" onClick={handleClose} disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={() => handleConfirm("negotiation")} loading={loading}>
          Salvar
        </Button>
      </Grid>
    </Box>
  );
};

export const OrderCanceledModal = ({
  cancelReasonList,
  selectedCancelRefused,
  handleChangeCancelRefused,
  handleChangeCancelObservation,
  canceledObservation,
  handleClose,
  handleConfirm,
  isOpen,
  orderData,
  obligatoryCancelReason,
  handleChangeCancelInfo,
}) => {
  const [stepCanceled, setStepCanceled] = useState(0);

  const clientName = orderData?.client?.trade_name || orderData?.client?.name;
  const clientType = orderData?.client.type;
  const AsaasPayment = orderData?.payment.filter(
    (payment) => payment.method_payment_name !== "wallet"
  );
  const paymentMethod = AsaasPayment
    ? AsaasPayment[0]?.method_payment_name
    : "wallet";
  const paymentStatus = AsaasPayment[1]?.status;
  const orderId = orderData?.id;
  const formattedValue = formatCurrency(orderData?.total_value);

  const nextStep = (step) => {
    if (!selectedCancelRefused) {
      return toast.error("Selecione um motivo");
    }
    if (
      obligatoryCancelReason?.includes(selectedCancelRefused) &&
      !canceledObservation
    ) {
      return toast.error("Escreva um comentário");
    }
    setStepCanceled(step);
  };

  const previousStep = () => {
    setStepCanceled((prev) => prev - 1);
  };

  const close = () => {
    setStepCanceled(0);
    handleClose();
  };

  return (
    <Modal open={isOpen}>
      <ModalBody handleClose={close}>
        <CancelRefusedContent>
          {!stepCanceled && (
            <InitialScreen
              canceledObservation={canceledObservation}
              cancelReasonList={cancelReasonList}
              close={close}
              handleChangeCancelObservation={handleChangeCancelObservation}
              handleChangeCancelRefused={handleChangeCancelRefused}
              nextStep={nextStep}
              selectedCancelRefused={selectedCancelRefused}
            />
          )}

          {stepCanceled === 1 && (
            <BasicRefuse
              clientName={clientName}
              clientType={clientType}
              paymentMethod={paymentMethod}
              paymentStatus={paymentStatus}
              orderId={orderId}
              previousStep={previousStep}
              formattedValue={formattedValue}
              handleConfirm={handleConfirm}
              handleChangeCancelInfo={handleChangeCancelInfo}
            />
          )}
        </CancelRefusedContent>
      </ModalBody>
    </Modal>
  );
};

export const NoSolutionModal = ({
  close,
  open,
  withOutSolutionObservation,
  handleConfirm,
}) => {
  return (
    <Modal open={open}>
      <ModalBody handleClose={close}>
        <NoSolutionModalTitleContent>
          <H1>Finalizar sem tratativa</H1>
        </NoSolutionModalTitleContent>
        <NoSolutionModalContent>
          <NoSolutionModalSubTitle>
            Qual é o motivo da finalização sem tratativa?
          </NoSolutionModalSubTitle>
          <DefaultInput
            inputRef={withOutSolutionObservation}
            label="Comentários"
            multiline
            rows={4}
            variant="outlined"
          />
        </NoSolutionModalContent>
        <NoSolutionModalAction>
          <Button btn="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button onClick={() => handleConfirm("withoutSolution")}>
            Salvar
          </Button>
        </NoSolutionModalAction>
      </ModalBody>
    </Modal>
  );
};
